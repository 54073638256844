/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Subtitle, Text, ContactForm } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Contact"}>
        <SiteHeader />

        <Column className="css-18p8swp --parallax mt--30 pb--0 pt--0" name={"contact"} parallax={true} css={css`
      
    background-image: url(https://cdn.swbpg.com/t/31729/7b7688225c1d4da99fe33139ce87e137_bl=1_ove=000000x25__s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/31729/7b7688225c1d4da99fe33139ce87e137_bl=1_ove=000000x25__s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: url(https://cdn.swbpg.com/t/31729/7b7688225c1d4da99fe33139ce87e137_bl=1_ove=000000x25__s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/31729/7b7688225c1d4da99fe33139ce87e137_bl=1_ove=000000x25__s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: url(https://cdn.swbpg.com/t/31729/7b7688225c1d4da99fe33139ce87e137_bl=1_ove=000000x25__s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: url(https://cdn.swbpg.com/t/31729/7b7688225c1d4da99fe33139ce87e137_bl=1_ove=000000x25__s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/31729/7b7688225c1d4da99fe33139ce87e137_bl=1_ove=000000x25__s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/31729/7b7688225c1d4da99fe33139ce87e137_bl=1_ove=000000x25__s=3000x_.jpg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --shape4 --center el--2 pl--0 pr--0 pt--50 flex--top" anim={null} animS={null} style={{"paddingBottom":46,"backgroundColor":"rgba(0,0,0,0)"}} columns={"2"}>
            
            <ColumnWrapper className="js-anim  --anim5 --anim-s3" anim={"5"} animS={"3"} style={{"maxWidth":510}}>
              
              <Title className="title-box title-box--left fs--62 title-box--invert" content={"<span style=\"color: var(--white);\">KONTAKT</span>"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper className="js-anim  --anim4 --left" anim={"4"} animS={null} style={{"maxWidth":510}}>
              
              <Subtitle className="subtitle-box fs--26 subtitle-box--invert lh--16" content={"<span style=\"color: var(--white);\"><a href=\"tel:0989188334\" style=\"\">0989188334</a><br><a href=\"mailto:bascisto@gmail.com\" style=\"\">bascisto@gmail.com</a></span><br>"}>
              </Subtitle>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="js-anim  --anim5 --anim-s3 pb--80 pt--80" anim={"5"} name={"contact-2"} animS={"3"}>
          
          <ColumnWrap className="column__flex el--2 pb--06 pl--02 pr--02 pt--06 flex--top" style={{"maxWidth":1400}} columns={"2"}>
            
            <ColumnWrapper style={{"maxWidth":520}}>
              
              <Title className="title-box" content={"<span style=\"color: rgb(19, 19, 211);\">KONTAKT OBRAZAC</span>"}>
              </Title>

              <Text className="text-box fs--26" content={"<span style=\"font-weight: bold; color: rgb(19, 19, 211);\">Popunite kontakt obrazac, povratno ćemo vam se javiti.</span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <ContactForm className="--shape2 --shape3 --style1 --right fs--20 lh--2" action={"/contact"} fields={[{"name":"IME I PREZIME","type":"text","required":true,"placeholder":""},{"name":"E MAIL ADRESA","type":"email","required":true,"placeholder":""},{"name":"TELEFONSKI BROJ","type":"text","placeholder":""},{"name":"PORUKA","type":"textarea","required":true,"placeholder":""},{"name":"POŠALJI","type":"submit","align":"left"}]}>
              </ContactForm>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}